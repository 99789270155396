@import '../../variables.scss';

.fixed-nav {
  position: fixed;
}

.navbar-nav {
  display: inline-block;
  float: none;
  vertical-align: top;

  li {
    a {
      font-size: 20px !important;
      font-weight: 600;
    }
  }
}