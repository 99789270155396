@import '../../variables.scss';

.slides{
  position: $rel;
  overflow: $hid;
  width: $vw100;
  height: 100%;

  .overlay {
    position: $abs;
    width: $per100;
    height: $per100;
    background: url($overlayImg);
    z-index: 2;
  }

  .slides-container {
    margin: 0px;
    padding: 0px;
    list-style: $none;
    display: $blk;
  }

  .welcome-message {
    position: $abs;
    width: $per100;
    height: 250px;
    top: $per50;
    margin-top: -25%;
    z-index: 10;
    font-weight: 600;
    text-align: $cen;
    font-family: $latoSS;
    text-shadow: $black4a-0-2-5;
    @include text-gradient(315deg, #045de9 0%, #09c6f9 74%);

    .heading {
      padding: 18px 0;
      @media screen and (max-width: $px550) {
        padding: 9px 0;
      }
    }
    p {
      font-size: 49px;
      -webkit-text-shadow: $black4a-0-2-5;
      -moz-text-shadow: $black4a-0-2-5;
      -o-text-shadow: $black4a-0-2-5;
      -ms-text-shadow: $black4a-0-2-5;
      @media screen and (max-width: $px850) {
        font-size: 39px;
      }
      @media screen and (max-width: $px550) {
        font-size: 29px;
      }
      @media screen and (max-width: $px400) {
        font-size: 19px;
      }

      .small {
        font-size: 29px;
      }
      &::selection{
        background-color: $white;
      }
    }

    .borderBtn {
      -webkit-border-radius: 3px;
      -moz-border-radius: 3px;
      -ms-border-radius: 3px;
      -o-border-radius: 3px;
      -webkit-transition: all .3s ease-in, 0s;
      -moz-transition: all .3s ease-in, 0s;
      -o-transition: all .3s ease-in, 0s;
      -ms-transition: all .3s ease-in, 0s;
      transition: all .3s ease-in, 0s;
      font-weight: 600;
      font-size: 22px;
      text-decoration: $none;
      @include text-gradient(315deg, #045de9 0%, #09c6f9 74%);
      border: 2px solid $white;
      display: $inblk;
      border-radius: 3px;
      padding: 18px 50px;

      @media screen and (max-width: $px850) {
        padding: 14px 40px;
      }
      @media screen and (max-width: $px550) {
        padding: 10px 30px;
      }
      @media screen and (max-width: $px400) {
        padding: 6px 20px;
      }
      &:focus, &:hover{
        background-color: $white;
        color: $red;
      }
      &:hover {
        -webkit-text-shadow: $trans-0-2-5;
        -moz-text-shadow: $trans-0-2-5;
        -o-text-shadow: $trans-0-2-5;
        -ms-text-shadow: $trans-0-2-5;
        text-shadow: $trans-0-2-5;
      }
    }
  }
}
