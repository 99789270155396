@import '../../variables.scss';
.hero {
  background-color: #d3d3d3;
  @include background-gradient(222deg, #2BC0E4 10%, #EAECC6 74%);

  color: $white;
}
.firstSec {
  padding-top: 30px;
  .fixed {
    padding-top: 80px;
  }
}
.section {
  padding: 30px 0 20px;
  h1, h2, h3, h4 {
    font-family: $latoSS;
    font-weight: 700;
    @include text-gradient(315deg, #232526 0%, #414345 74%);
    margin-bottom: 25px;
    font-size: 28px !important;
  }
  h3{
    font-size: 24px !important;
  }
  p {
    font-family: $latoSS;
    font-weight: $px400;
    font-size: 15px;
    line-height: 22px;
  }

  .container {
    margin: 0 auto;
    padding: 25px 15px;
    width: 100%;

    .profilePic {
      text-align: $cen;
      margin: 0 auto;
      float: $none;

      .img-circle {
        border-radius: $per30;
        width: $per100;
      }
    }
  }

  .heading {
    text-align: $cen;
    padding-bottom: 40px;

    .borderBtn {
      -webkit-border-radius: 3px;
      -moz-border-radius: 3px;
      -ms-border-radius: 3px;
      -o-border-radius: 3px;
      -webkit-transition: all .3s ease-in, 0s;
      -moz-transition: all .3s ease-in, 0s;
      -o-transition: all .3s ease-in, 0s;
      -ms-transition: all .3s ease-in, 0s;
      transition: all .3s ease-in, 0s;
      font-weight: 600;
      font-size: 22px;
      text-decoration: $none;
      @include text-gradient(315deg, #045de9 0%, #09c6f9 74%);
      border: 2px solid $white;
      display: $inblk;
      border-radius: 3px;
      padding: 18px 50px;

      @media screen and (max-width: $px850) {
        padding: 14px 40px;
      }
      @media screen and (max-width: $px550) {
        padding: 10px 30px;
      }
      @media screen and (max-width: $px400) {
        padding: 6px 20px;
      }
      &:focus, &:hover{
        background-color: $white;
        color: $red;
      }
      &:hover {
        -webkit-text-shadow: $trans-0-2-5;
        -moz-text-shadow: $trans-0-2-5;
        -o-text-shadow: $trans-0-2-5;
        -ms-text-shadow: $trans-0-2-5;
        text-shadow: $trans-0-2-5;
      }
    }
  }
}