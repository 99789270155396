$per100: 100%;
$per50: 50%;
$per40: 40%;
$per30: 30%;
$per25: 25%;
$per20: 20%;
$vh100: 100vh;
$vw100: 100vw;
$px850: 850px;
$px550: 550px;
$px400: 400px;

$abs: absolute;
$rel: relative;
$hid: hidden;
$none: none;
$blk: block;
$inblk: inline-block;
$btm: bottom;
$tp: top;
$cen: center;

$overlayImg: "/images/overlay.png";

$latoSS: Lato, sans-serif;
$trans-0-2-5: 0 2px 5px transparent;
$black4a-0-2-5: 0 2px 5px rgba(0, 0, 0, .4);

$white: #fff;
$black: #000;
$blue: #1870e4;
$red: #d82c2e;

@mixin border-gradient($dir, $from, $to, $width) {
  border-left: $width solid $from;
  border-right: $width solid $to;
  background-image:
    // Needed for both the top and bottom borders
    -webkit-linear-gradient($dir, $from, $to),
    -webkit-linear-gradient($dir, $from, $to);
  background-size: 100% $width;
  background-position: 0 100%, 0 0;
  background-repeat: no-repeat;
  background-clip: border-box;
}

@mixin background-gradient($dir, $from, $to) {
  background-image:
    -webkit-linear-gradient($dir, $from, $to),
}

@mixin text-gradient($dir, $from, $to) {
  background: -webkit-linear-gradient($dir, $from, $to);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
// Gradient Options
//     chill: linear-gradient(135deg, #09e485 0%,#0291e3 100%),
//     warm: linear-gradient(135deg, #F2CD48 0%,#FD803F 100%),
//     hot: linear-gradient(135deg, #F9445A 0%,#FD803F 100%),
//     cold: linear-gradient(135deg, #8019C6 0%,#2F93CC 100%)
